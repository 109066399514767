
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/jquery-fancybox/source/scss/jquery.fancybox.scss";

// Admin
@import "admin";

.ng-cloak, [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .x-ng-cloak {
  display: none !important;
}

html {
    height: 100%;
    box-sizing: border-box;
}
body {
    position: relative;
    padding-bottom: 150px;
    min-height: 100%;
}

img {
    max-width: 100%;
}

.navbar-brand {
    padding: 5px;
    img {
        height: 40px;
    }
}
.admin-buttons-top button {
    padding: 5px 10px;

    & a {
        color: #fff;
        text-decoration: none;
    }
    & a:hover {
        text-decoration: none;
    }
}

.admin-all-orders {
    .admin-orders-warning:first-of-type {
        display: block;
    }
    .admin-orders-warning {
        font-weight: bold;
        background-color: red;
        padding: 5px 10px;
        border-radius: 4px;
        display: none;
    }
    a {
        p {
            margin: 0;
        }
    }

}

.admin-orders {
    padding: 10px 0px 20px 50px;

    p {
        padding-left: 10px;
        font-size: 16px;

        span {
            font-weight: bold;
            letter-spacing: 1px;
        }
    }
}

.admin-all-admins {
    margin-bottom: 100px;
}


.client-heading {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
}
.media-object {
    max-height: 50px;
}

.dndPlaceholder {
    list-style-type: none;
    height: 50px;
    background: #eee;
    border-radius: 5px;
    margin-bottom: 25px;
}

.dndDraggingSource {
    display:none;
}

.ng-binding.panel-body {
    padding: 50px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.photo-thumbnail {
    position: relative;
    width: 120px;
    float: left;
    height: 120px;
    background: #eee;
    margin: 8px;

    &.loading {

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -15px;
            margin-left: -15px;
            width: 30px;
            height: 30px;
            border: 5px solid;
            border-color: #000 rgba(0,0,0,.2) rgba(0,0,0,.2) rgba(0,0,0,.2);
            border-radius: 500px;
            animation: spin 1s linear infinite;
        }

    }

    overflow: hidden;

    .photo-meta {
        position: absolute;
        bottom: 0;
        background: rgba(0,0,0,.8);
        color: #fff;
        width: 100%;
        padding: 2px;
        opacity: 0;
        transform: translateY(10px);
        transition: all .3s ease;
    }

    &:hover {
        .photo-meta {
            opacity: 1;
            transform: translateY(0);
        }
    }

}
.tab-pane {
    &.dragover {
        background: #f8f8f8;
        border: 10px dashed #333;
    }
    &.panel-body {
        min-height: 100px;
        padding: 10px;
    }
}

body.dragging .drop-box,
body.dragging [dnd-draggable="category"] .panel-footer,
.dndDragging .panel-body,
.dndDragging .panel-footer,
.dndDragging .table {
    display:none;
}

.nav-pills {
    margin-top: 10px;
}

.table {
    & input[type="checkbox"] {
        margin: 5px;
        display: inline-block;
    }
    & input[type="checkbox"]:first-of-type {
        margin-right: 12px;
    }
}

input[name="optradio"] {
    text-align: center;
}

.progress-container {
    z-index: 7000;
    width: 200px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 25px;
}

.progress {
    height: 10px;
    box-shadow: 1px 1px 4px #777;
    margin-bottom: 10px;
    border-radius: 10px;
}

.progress-bar {
    background-color: #5CB85C !important;
    display: block;
}


.table tbody tr td:last-of-type {
    border: none;
}

.del-photo {
    margin-left: 15px;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}


//********************* FANCYBOX **********************//

.fancybox-title {
    height: 70px;
    min-width: 700px;
    margin: 10px 0px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);

    .fancy-btn {
        width: auto;
        background-color: transparent;
        font-size: 14px;
        line-height: 28px;
        height: 30px;

        .fancy-outer-span {
            color: #fff;
            background-color: #222;
            position: relative;
            width: auto;
            min-width: 130px;
            text-align: left;
            display: block;
            padding-left: 10px;
            padding-right: 45px;
            border-radius: 4px;
            border: 1px solid #fff;

            span {
                color: #fff;
                background-color: inherit;
                position: absolute;
                text-align: right;
                right: 0;
                padding: 0px 10px;
                top: 0;
                height: 28px;
                border-radius: 0px 4px 4px 0px;
                border-left: 1px solid #fff;
            }
        }
    }
    .fancy-middle {
        font-size: 14px;
        text-align: center;
        p {
            margin: 0 0 3px;
        }
    }

}

.fancybox-close {
    display: none;
}

.fancybox-skin:hover .fancybox-close {
    display:block;
}

#addToCart3 {
    left: 0;
}
#addToCart4 {
    left: 0;
    top: 40px;
}
#addToCart5 {
    right: 0;

    .fancy-outer-span {
        padding-right: 75px;

    }
}

#addToCart3:hover,
#addToCart4:hover,
#addToCart5:hover  {
    .fancy-outer-span {
        background-color: #5CB85C;
        text-decoration: none;
        border: 1px solid #5CB85C;

        span {
            border-left: 1px solid #fff;
        }
    }
}




//********************* FANCYBOX **********************//



//********************* CD-CART **********************//

.cd-cart-trigger {
    z-index: 2300;
    height: 72px;
    width: 72px;
    white-space: nowrap;
    position: fixed;
    bottom: 50px;
    right: 5%;
    backface-visibility: hidden;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
    opacity: 1;
    -webkit-animation: fadein 3s linear;
    animation: fadein 2s linear;
}

.cd-cart-trigger::after, .cd-cart-trigger::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    width: 100%;
    background: url(../img/cd-icon-cart.svg) no-repeat 0 0;
}

@-webkit-keyframes fadein {
    0%   {
        transform: scale(0);
    }
    60% {
        transform: scale(1.4);
    }
    90% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fadein {
    0%   {
        transform: scale(0);
    }
    40% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.cd-cart-trigger::after {
    background-position: -72px 0;
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(90deg);
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.cd-cart-trigger .count {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 28px;
    width: 28px;
    background: #41AD34;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    text-indent: 0;
    -webkit-transition: -webkit-transform .2s .5s;
    transition: -webkit-transform .2s .5s;
    transition: transform .2s .5s;
    transition: transform .2s .5s, -webkit-transform .2s .5s;
    list-style: none;
    margin: 0;
    padding: 0;
}
.cd-cart-trigger .count li {
    position: absolute;
    left: 50%;
    top: 45%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}




//********************* CD-CART **********************//




//********************* PUBLIC INDEX **********************//

.welcome {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    height: 80vh;
    min-height: 400px;
}
.welcome-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    h1 {
        padding-bottom: 30px;
    }
}
.top-wrapper {
    margin-bottom: 30px;
    margin-top: -30px;
    position: relative;

    h1 {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -1px #333;
        font-weight: bold;
    }
    img {
        width: 100%;
    }
}
.public-container {
    margin: 30px;
}

.panel-public {
    .panel-heading:hover {
        h3 {
            font-weight: bold;
            cursor: pointer;
        }
    }
}

.faq-block {
    width: 80%;
    box-shadow: 10px 10px 21px;
    background-color: #fff;
    margin: 50px auto 50px;

    .faq-content {
        margin: 0px 100px;
        padding: 30px 0 41px;
    }
}

#carty {
    width: 100%;

    img {
        width: 60px;
    }
    .tfoot-td {
        text-align: center;
        padding-top: 30px;
    }
    tbody {
        tr td {
            vertical-align: middle;
            padding: 5px;
            width: 20%;
        }
        tr input[type="number"] {
            width: 50px;
            text-align: center;
        }

    }
    #goToCheck {
        padding: 6px 0;
        background-color: #41AD34;

        a {
            color: #fff;
            height: inherit;
            padding: 10px 12px;
        }
        a:hover,
        a:focus {
            text-decoration: none;
        }
    }
    #goToCheck:hover {
        background-color: #6EC064;
    }
}
.panel-body-public {
    padding: 15px 0 8px;
}

.links-container {

    &:hover > .links {
        color: #a1b2bb;
    }

    .links {
        padding: 10px 0px 10px;
        color: #333;
        display: block;
        margin: 0 5px 0 5px;
        text-decoration: none;
    }
    & .links:hover {
        color: #333;
    }
}

.public-thumbnail {
    width: 120px;
    height: 120px;
    margin: 0;

    & img {
    -webkit-transition: all 4s ease;
    -moz-transition: all 4s ease;
    -ms-transition: all 4s ease;
    transition: all 4s ease;

        &:hover {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
}

.photo-wrapper {
    position: relative;
    display: inline-block;
    margin: 3px 3px 3px 5px;
}

.photo-details {
    position: absolute;
    z-index: 2000;
    width: 100%;
    left: 0px;
    top: 5px;
}

.add-wrapper {
    height: inherit;
    width: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 31px;
    border: none;
    padding: 0;
    position: absolute;

    span {
        color: #fff;
        display: inline-block;
        height: inherit;
        position: relative;

        a {
            text-decoration: none;
            color: #fff;
            height: 30px;
            width: 30px;
            display: block;
        }
    }

}

#addToCart1 {
    left: 5px;
}

#addToCart2 {
    right: 5px;
}
#addToCart1:hover,
#addToCart2:hover,
.inCartClass {
    background-color: #41AD34;
    border-radius: 4px;

    a {
        color: #fff;
        i {
            font-size: 20px;
            vertical-align: middle;
        }
    }
}
.tooltip-inner {
    text-align: center;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-bottom: 7px;
    color: #fff;
    background-color: #000;
    font-size: 14px;
    width: 120px;
}
.tooltip-arrow {
    margin-bottom: 7px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #d3e0e9;
    background-color: #fff;
    text-align: center;

    a {
        display: block;
        width: 200px;
        margin: 0 auto;

        .footer-img {
            height: 65px;
            background: url(../img/icon-green.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-top: 10px;
        }
    }

    p {
        padding: 5px 0 15px;
        margin: 0;
        font-size: 12px;

        a {
            color: inherit;
        }
        a:hover,
        a:focus {
            text-decoration: none;
        }
    }
}

.line-through {
    text-decoration: line-through;
    color: #41AD34;
}



//********************* Checkout **********************//

.form-wrapper,
.summary-wrapper {
    padding: 20px 50px 0px 0px;
}
.summary-wrapper {

    padding: 45px 20px 0px 0px;

    .summary-table {


        img {
            width: 50px;
            height: 50px;
            text-align: left;
        }


        tbody tr {

            padding: 10px;

            .summary-sm-text {
                font-size: 10px;
                text-align: center;
            }

            .checkout-quantity {
                padding-left: 40px;
            }

            td {
                width: 25%;
                padding: 10px;
                vertical-align: middle;
            }

        }


        .summary-end {

            td {
                vertical-align: middle;
            }
            tr td:first-of-type {
                text-align: right;
                padding-right: 0px;
            }

        }
        td:last-of-type {
            text-align: right;
            border-top: 1px solid #ddd;
            font-size: 16px;
        }
    }
    .proceed-checkout .btn-success {
        background-color: #41AD34;
    }
    .proceed-checkout .btn-success:hover {
        background-color: #6EC064;
    }
    .disclaimer {
        font-size: 10px;
        text-align: right;
        margin-top: 15px;
    }
}

//********************* Checkout **********************//




//********************* Account **********************//


.account-container {
    h1 {
        margin: 50px 0px;
    }
    .nav-tabs,
    .nav-justified {
        li {

            a {
                color: #777;
            }

        }

        li.active > a {
            color: #555;
            background-color: #fff;
            border-bottom-color: #fff !important;

            li.active > a:hover {
                border-bottom-color: #fff;
            }
        }


    }
    .account-panel {
        border-top: none;
        border-radius: 0px 0px 4px 4px;
    }
    #profile {
        .table {
            tr {
                width: 20%;
                th {
                    border: none;
                }
                td {
                    padding: 2px;
                    padding-left: 8px;
                    border: none;
                }
            }
        }
    }
    #information {
        form {
            .btn-success {
                background-color: #41AD34;
                margin: 15px 0 25px;
                width: 100%;
            }

            .btn-success:hover {
                background-color: #6EC064;
            }
        }
    }
    #orders {
        .orders {
            border-bottom: 1px solid #777777;
            margin-bottom: 20px;
            padding-top: 20px;
            .btn-success {
                background-color: #41AD34;

                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
            .btn-success:hover {
                background-color: #6EC064;
            }

            .order-item {

                .table {
                    margin-bottom: 12px;
                    tbody {
                        border-top: none;

                        tr {
                            th {
                                border-top: none;
                            }
                            td {
                                vertical-align: middle;

                                img {
                                    width: 50px;
                                    height: 50px;
                                }

                            }
                            td:last-of-type {
                                border-top: 1px solid #ddd;
                            }
                        }
                        tr:last-of-type {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    #downloads {
        a:hover {
            text-decoration: none;
        }
        .downloads-container {
            display: inline-block;
            width: 33%;
            position: relative;
            margin-bottom: 3px;

            .overlay {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .5);
                position: absolute;
            }
            .account-images {
                width: 100%;
            }
            .account-preview,
            .account-download {
                width: 130px;
                height: 30px;
                color: #fff;
                border: 2px solid #fff;
                border-radius: 4px;
                line-height: 18px;
                margin: auto;
                padding: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: center;
            }
            .account-preview:hover,
            .account-download:hover {
                text-decoration: none;
                color: #000;
                background-color: #fff;
            }
            .account-preview:focus,
            .account-download:focus {
                outline: none;
                text-decoration: none;
            }
            .account-preview {
                top: -40px;
            }
            .account-download {
                top: 40px;
            }
        }
    }
    #password {
        form {
            .btn-success {
                background-color: #41AD34;
                margin: 15px 0 25px;
                width: 100%;
            }

            .btn-success:hover {
                background-color: #6EC064;
            }
        }
    }
}


//********************* Account **********************//


//********************* Receipt **********************//

.receipt {
    box-sizing: border-box;

    .receipt, th, td {
        padding: 10px 0px 10px 15px;
    }
}

.receipt1 {
    margin: 0;
    text-align: center;
    margin: 30px auto;
    th, td {

    }
}
.details-container {
    position: relative;
    width: 100%;
    min-height: 280px;

    .receipt2,
    .receipt3 {
        position: absolute;
        display: inline-block;
        margin: 0px;
        border: 1px solid #ddd;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
        tbody {
            display: table;
            margin: 0 auto;
            tr {
                td {
                    text-align: right;
                    min-width: 200px;
                    padding: 5px 20px 0px;
                }
            }
        }
    }
    .receipt3 {
        margin-bottom: 92px;
        right: 0;
    }
}

.receipt4 {
    clear:both;
    width: 100%;
    margin-left: 0;
    tbody tr th,
    tbody tr td {
        width: 16.666%;
    }
    tbody tr:first-child {
        background-color: #ddd;
    }

    .table-summary {
        border-top: 1px solid black;
        th, td {
            padding-top: 20px;
        }
    }
}


//********************* receipt **********************//




@media screen and (max-width: 991px) {


//******************** PUBLIC EVENT ******************//

    .links-container {
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }
    .panel-public {
        width: 100%;
    }

    #carty {

        thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            display: none;
        }
    }
    .faq-block {
        width: 90%;
        .faq-content {
            margin: 0px 50px;
            padding: 20px 0 31px;
        }
    }
    .form-wrapper {
        padding: 0;
        padding-bottom: 20px;
    }

//******************** PUBLIC EVENT ******************//


//******************** ACCOUNT ******************//


    .account-container #downloads .downloads-container {
        width: 49%;
    }


//******************** ACCOUNT ******************//


//******************** RECEIPT ******************//

    .details-container {
        text-align: center;

        .receipt2, .receipt3 {
            position: inherit;
        }

        .receipt3 {
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }

//******************** RECEIPT ******************//

}

@media screen and (max-width: 768px) {

//******************** PUBLIC EVENT ******************//
    .fancybox-title {
        height: 250px;
        min-width: auto;

        .add-wrapper {
            left: 50% !important;
            transform: translateX(-50%);

            .fancy-outer-span {
                display: inline-block;
            }
        }
        #addToCart3 {
            margin-top: 90px;
        }
        #addToCart4 {
            margin-top: 85px;
        }
        #addToCart5 {
            margin-top: 160px;
            right: auto;
        }
    }
}

//******************** PUBLIC EVENT ******************//



@media screen and (max-width: 480px) {

//******************** PUBLIC EVENT ******************//
    .public-container {
        margin: 0;
    }
    .panel-body-public {
        text-align: center;
        .public-thumbnail {
            width: 130px;
            height: 130px;

            img {
                width: 130px;
                height: 130px;
            }
        }
    }
    .faq-block {
        width: 100%;
        .faq-content {
            margin: 0px 30px;
            padding: 10px 0 21px;
        }
    }


//******************** PUBLIC EVENT ******************//


//******************** ACCOUNT ******************//


    .account-container {

        h1 {
            font-size: 28px;
        }

        #downloads .downloads-container {
            width: 100%;
            margin-bottom: 30px;
        }
    }

//******************** ACCOUNT ******************//


//******************** RECEIPT ******************//

    .receipt-container {


        .details-container .receipt2 tbody tr td,
        .details-container .receipt3 tbody tr td {
            text-align: right;
            min-width: 100px;
            padding: 5px 0px 0px;
        }
    }

//******************** RECEIPT ******************//

}
